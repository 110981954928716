













































import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudentHistoryHeader from '@/components/organisms/v2/StudentHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SelectSubject from '@/components/atoms/SelectSubject.vue'
import SelectPeriod from '@/components/organisms/SelectPeriod.vue'
import ChartLineUnderstandingRelative, { ChartRecord } from '@/components/atoms/ChartLineUnderstandingRelative.vue'
import AccordionStudentHistory, { GradeHistories } from '@/components/organisms/v2/AccordionStudentHistory.vue'
import SubjectSituationWebApi from '@/mixins/historyv2/SubjectSituationWebApi'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    StudentHistoryHeader,
    ColoredBox,
    ChartLineUnderstandingRelative,
    SelectSubject,
    SelectPeriod,
    AccordionStudentHistory,
  },
})
export default class SubjectSituation extends Mixins(
  SubjectSituationWebApi,
  LoadEnableSubjectsApi,
  ClassModeChangeAble
) {
  // クラスモード: 補助教材以外全てのモード
  private readonly classMode = 'TJ,TK,KS,NY,MN,AI'

  private selectedSubject = this.$route.query.selectedSubject ? String(this.$route.query.selectedSubject) : ''

  // 教科プルダウン表示対象、教科コードの配列 ex.=> ['su', 'ei', 'ko']
  private enableSubjects: string[] = []

  private selectedTerm = '1week'

  private get branchId() {
    return Vue.prototype.$cookies.get('dataGdls').branchId
  }

  private get studentUserId() {
    return Vue.prototype.$cookies.get('authGdls').currentApiUserId
  }

  private get studentGradeCode() {
    return Vue.prototype.$cookies.get('dataGdls').grade.code
  }

  private get enableSelectedSubject() {
    return this.enableSubjects.includes(this.selectedSubject) ? this.selectedSubject : ''
  }

  // チャートデータ
  private chartDatas: ChartRecord[] = []

  // 学習結果
  private result = {
    questionCount: 0,
    correctCount: 0,
    hour: 0,
    minute: 0,
  }

  // 表示日付
  private dateSteps: string[] = []

  // 項目毎の累計理解度 rateの位置は最大値の位置で表示する
  private gradeHistories: GradeHistories[] = []

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.loadEnableSubjects()
    await this.loadChartRecords()
    await this.loadGradeHistories()
    Vue.prototype.$loading.complete()
  }

  // 期間プルダウン変更時
  private async onChangeTerm() {
    Vue.prototype.$loading.start()
    // チャート情報のみ取得
    await this.loadChartRecords()
    Vue.prototype.$loading.complete()
  }

  // 教科プルダウン変更時
  private async onChangeSubject() {
    Vue.prototype.$loading.start()
    // チャート情報と理解度リストどちらも取得
    await this.loadChartRecords()
    await this.loadGradeHistories()
    Vue.prototype.$loading.complete()
  }

  /**
   * チャート情報取得
   */
  private async loadChartRecords() {
    if (this.selectedSubject == '') return

    const { chartDatas, result, dateSteps } = await this.loadChartRecordsApi({
      userId: this.studentUserId,
      term: this.selectedTerm,
      subjectCode: this.selectedSubject,
      classMode: this.classMode,
    })
    // チャート用のスコア配列をセット
    this.chartDatas = chartDatas
    // 学習結果セット
    this.result = result
    // 表示日付セット
    this.dateSteps = dateSteps
  }

  /**
   * 項目毎の理解度取得
   */
  private async loadGradeHistories() {
    if (this.selectedSubject == '') return

    const result = await this.loadGradeHistoriesApi({
      userId: this.studentUserId,
      subjectCode: this.selectedSubject,
    })
    // 項目毎の累計理解度セット
    this.gradeHistories = result['curriculums']
  }

  /**
   * サービス設定で有効な教科を取得し、教科プルダウンに反映
   */
  private async loadEnableSubjects() {
    // プルダウン候補の取得
    this.enableSubjects = (await this.loadGdlsSubjectsApi(this.branchId, this.studentUserId)).map((subject) => {
      return subject.code
    })
    // デフォルトの教科セット
    this.selectedSubject = this.selectedSubject === '' ? this.enableSubjects[0] : this.selectedSubject
  }
}
