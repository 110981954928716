import { Component, Vue } from 'vue-property-decorator'
import { GetSubjectResultSummary } from '@/models/api/history/subjectResult'
import { GradeCurriculumsResult } from '@/models/api/v2/history/curriculums'

@Component
export default class SubjectSituationWebApi extends Vue {
  /**
   * チャート表示用データと、学習結果を取得する
   * @param { userId: number; term: string; subjectCode: string; classMode: string }
   */
  protected async loadChartRecordsApi(params: {
    userId: number
    term: string
    subjectCode: string
    classMode?: string
  }) {
    const { data }: { data: GetSubjectResultSummary } = await Vue.prototype.$http.httpWithToken.get(
      `/v2/history/subjectResult/summary`,
      {
        params: params,
      }
    )

    // チャート用のスコア配列をセット
    const chartDatas = data.scores

    // 学習結果セット
    const result = {
      questionCount: data.quesitonCounts,
      correctCount: data.correctCounts,
      hour: data.duration.hour,
      minute: data.duration.minute,
    }

    // 表示日付セット
    const dateSteps = data.dateSteps

    return { chartDatas, result, dateSteps }
  }

  /**
   * 項目毎の理解度一覧を取得
   * @param { userId: number; subjectCode: string }
   */
  protected async loadGradeHistoriesApi(params: { userId: number; subjectCode: string }) {
    const { data }: { data: { curriculums: GradeCurriculumsResult[] } } = await Vue.prototype.$http.httpWithToken.get(
      `/v2/history/subjectResult/curriculums`,
      {
        params: params,
      }
    )

    // 項目毎の累計理解度セット
    const curriculums = data.curriculums.map((curriculumData) => {
      return this.parseGradeHistories(curriculumData)
    })

    return { curriculums: curriculums }
  }

  /**
   * APIで受け取った学年ごとの理解度一覧をパースする
   * @param GradeCurriculumsResult
   */
  private parseGradeHistories(curriculumData: GradeCurriculumsResult) {
    return {
      gradeName: curriculumData.gradeName,
      gradeCode: curriculumData.gradeCode,
      histories: curriculumData.curriculumSUnits.map((unit) => {
        const rates = [
          { rate: unit.predictedScoreMax, rateText: `${unit.predictedScoreMin}-${unit.predictedScoreMax}` },
        ]
        const count = Object.values(unit.count).reduce((sum: number, cnt: number) => sum + cnt, 0)
        return {
          sUnitId: unit.sUnitId,
          subject: { name: unit.subjectName, code: unit.subjectCode },
          content: unit.sUnitName,
          date: unit.date,
          count: count,
          rates: rates,
        }
      }),
    }
  }
}
